/* code base style */
body {
  position: relative;
}
ul li {
  list-style: none;
}
.content {
  overflow: hidden;
}
.content-header {
  height: 50px;
}
#page-container.page-header-fixed #page-header > .content-header {
  padding-top: 0px;
  padding-bottom: 0px;
}
.sidebar-content .content-header,
#page-header {
  background-color: #4267b2;
}
#page-header {
  /* background-color: #506dad; */
}
#page-header .content-header-section .btn {
  color: #fff;
}
#page-container.sidebar-inverse #sidebar {
  background-color: #fff;
  z-index: 99;
}
.nav-main {
  padding: 0px 10px;
}
.nav-main li {
  margin-bottom: 2px;
}
.sidebar-inverse #sidebar .nav-main a {
  color: #202224;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}
.sidebar-inverse #sidebar .nav-main a > i {
  color: #202224;
}
.sidebar-inverse #sidebar .nav-main a.active,
.sidebar-inverse #sidebar .nav-main a:hover {
  background: #4080ff;
  color: #fff;
  border-radius: 5px;
}
.sidebar-inverse #sidebar .nav-main a.active > i,
.sidebar-inverse #sidebar .nav-main a:hover > i {
  color: #fff;
}
.sidebar-inverse #sidebar .nav-main a:focus > i {
}
#page-container.page-header-fixed #main-container {
  padding-top: 50px;
}
.content-side {
  padding: 24px 18px;
}
.block-rounded {
  border-radius: 5px !important;
}
.block.block-rounded > .block-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.label-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #202224;
  margin-bottom: 10px;
}
.input-text {
  height: 44px;
  color: #636466;
  font-size: 14px;
}
.block-title {
  font-size: 22px;
}
.btn-m {
  background-color: #4080ff;
  color: #fff;
}
.btn.btn-m:hover {
  color: #fff;
  text-decoration: none;
}

.badge-primary {
  border-color: #3f9ce8;
}
.table-text-center {
  text-align: center;
}
.breadcrumb-item:not(.active) {
  color: #202224;
  text-decoration: underline;
}
.breadcrumb-item:not(.active):hover {
  color: #202224;
  text-decoration: underline;
}
.money-wallet {
  width: 100%;
  border: 1px solid #e8e9ef;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 180px;
  padding: 15px 20px;
  margin-top: 10px;
}
.loader-m {
  display: inline-block;
  border-radius: 50%;
  border: 2px solid #f3f3f3;
  border-top-color: #ff8943;
  width: 20px;
  height: 20px;
  -webkit-animation: spinner-border 1s linear infinite;
  animation: spinner-border 1s linear infinite;
  margin: 20px auto;
}
.load-spin-box {
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
}
.btn-payment-success {
  cursor: pointer;
}
.shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.header-acc-name {
  height: 30px;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #fff;
  color: #000;
  font-size: 0.9rem;
  padding: 5px 15px;
  border-radius: 20px;
}
.header-acc-name,
.header-container-left,
.header-container-right {
  display: flex;
  align-items: center;
}
.ant-pagination li a {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.table-packages tr:not(:last-child) {
  border-bottom: 1px solid #ffffff;
}
.nav-main-heading {
  /* border-top: 1px solid #e4e7ed!important; */
}
.nav-main-heading span {
  color: #222222;
}
.block-content {
  padding-bottom: 20px;
}
.list-select-menu {
  margin: 0px;
  padding: 0px;
}
.list-select-menu li:not(:last-child) {
  margin-bottom: 18px;
}
.list-select-menu li {
  font-weight: 600;
  color: #222222;
}
.list-select-menu li i {
  color: #4080ff;
  margin-right: 5px;
}
#page-header {
  z-index: 99 !important;
}
.ant-form-item-label {
  text-align: left;
  width: 100%;
}
.nav-main li.open .nav-submenu {
  font-weight: 500;
}
.nav-main li.open > ul li a {
  padding-left: 10px;
}
.ant-modal-content {
  border-radius: 5px;
}
.ant-modal-content .ant-modal-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.ant-form-item {
  margin-bottom: 20px;
}
.ant-picker-suffix,
.ant-picker-clear {
  display: flex;
}
.table-package .disabled input,
.table-package .disabled select {
  /* background-color: #dddddd!important; */
  /* pointer-events: none; */
}
.table-white-space th,
.table-white-space td {
  white-space: nowrap;
}
.table-package-mb input[name="number_page"] {
  width: 60px !important;
}
.detail-payment h5 span {
  font-size: 20px;
  border-left: 3px solid #4267b2;
  line-height: 34px;
  padding-left: 10px;
}
#sidebar .content-header {
  position: relative !important;
}
#sidebar .content-header-section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.guides .block-content {
  font-size: 15px;
}
.guides .block-content ul li {
  list-style-type: circle;
}
.guides .block-content p {
  margin-bottom: 0px;
}
.guides .block-content img {
  max-width: 100%;
}
.guides .video {
  left: 0;
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
}
.guides .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.discount-label {
  padding: 1px 5px;
    position: relative;
    width: 37px;
    border-radius: 0 4px 4px 0;
    position: absolute;
    right: 0;
    top: -22px;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
}

.discount-label:after {
  right: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    border-color: rgba(136, 183, 213, 0);
    border-width: 10px;
    top: 50%;
    /* margin-top: -20px; */
    transform: translateY(-50%);
    border-right-color: red;
}

.discount-label:before {
  content: "";
  z-index: 2;
  position: absolute;
  top: 50%;
  right: 100%;
  width: 4px;
  height: 4px;
  opacity: 0.95;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: inset 0.5px 0 rgb(0 0 0 / 60%);
  transform: translateY(-50%);
}

.discount-label span {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  font-family: "Raleway", Helvetica;
}

.amount-before-discount{
  margin-right: 5px;
  font-size: 13.5px;
  color: #636466;
  text-decoration: line-through;
}
.amount-after-discount{
  font-size: 16px;
}

@media (min-width: 992px) {
  #sidebar,
  #page-container.side-scroll #sidebar .content-header,
  #page-container.side-scroll #sidebar .content-side {
    width: 288px !important;
  }
  #page-container.page-header-fixed.sidebar-o #page-header,
  #page-container.page-header-glass.sidebar-o #page-header {
    left: 288px;
  }
}
@media (max-width: 992px) {
  #sidebar .content-header {
    height: auto;
  }
}

@media (max-width: 767.98px) {
  .row:not(.gutters-tiny):not(.no-gutters) {
    margin: -6px;
  }
  .row:not(.gutters-tiny):not(.no-gutters) > .col,
  .row:not(.gutters-tiny):not(.no-gutters) > [class*="col-"] {
    padding: 6px;
  }
  #sidebar .content-header .logo img {
    width: 80px !important;
  }
}
